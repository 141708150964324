.custom-button {
    background-color: black;
    color: white;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .custom-button:hover {
    background-color: #EEEE00;
    color: #000000;
  }
  