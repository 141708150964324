input[type='checkbox'] {
    accent-color: #09090A;
}

input[type='checkbox'] {
    height: 17px;
    width: 17px;
    border-radius: 30%;
}

.btnhover:hover{
    background-color: #53545C;
    transition: background-color ease-in-out 0.4s;
}