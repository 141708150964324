.small-font {
    font-size: xx-small;
}

.table-d td,
th {
    text-align: center;
}

.table-d td {
    font-size: 95%;
}