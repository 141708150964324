.sidebar {
  width: 15vw;
  height: 100vh;
  background-color: #FAFAFA;
  display: flex;
  flex-direction: column;
  transition: width 0.3s ease;
}

.sidebar.collapsed {
  width: 60px;
}

.sidebar-header {
  display: flex;
  align-items: center;
  padding: 20px;
  cursor: pointer;
}

.sidebar-header svg {
  margin-right: 10px;
}

.sidebar-menu {
  list-style: none;
  padding: 0;
  overflow: hidden;
}

.sidebar-menu-item {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.sidebar-menu-item svg {
  margin-right: 10px;
}

.sidebar-menu-text {
  display: block;
  transition: opacity 0.3s ease;
}

.collapsed-text {
  opacity: 0;
}

.parent-menu {
  /* padding: 10px 10px; */
  margin: 0 10px;
  color: #ADB5BD;
  user-select: none;
  font-weight: bold;
}

.parent-menu:hover {
  background-color: #EAEAEA;
  color: #ADB5BD;
  cursor: pointer;
}

.active-link {
  background-color: #555;
}

.nav-link {
  text-decoration: none;
  color: inherit;
}

.submenu-items {
  list-style: none;
  margin: 0;
  padding: 0;
}

.submenu-item {
  padding: 10px 20px 10px 30px;
  margin: 0 10px;
  user-select: none;
  color: #ADB5BD;
}

.submenu-item:hover {
  background-color: #EAEAEA;
  color: #000;
}

.active-item {
  background-color: #333333;
  color: #FFFFFF;
}

.submenu-item a {
  color: inherit;
  text-decoration: none;
}

.footer-btn {
  background-color: #fff;
  cursor: pointer;
}

.footer-btn:hover {
  background-color: #EAEAEA;
}