:root {
  --grey: #808080;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "PolySans Neutral";
}

::placeholder {
  color: #9C9C9C;
  opacity: 0.5;
}

.search-container {
  position: relative;
  display: inline-block;
}

.search-input {
  padding-right: 30px;
  /* Add padding to make space for the icon */
  width: 100%;
  box-sizing: border-box;
  /* Ensure padding doesn't affect width */
}

.search-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.transparent-table td,
.transparent-table th {
  background-color: transparent !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.custom-dropdown-toggle::after {
  display: none !important;
  /* Hides the default arrow */
}

/* For React-DatePicker CSS */
/* Align the calendar to the left */
.react-datepicker__triangle {
  left: 10px !important;
  /* Adjust this value for exact positioning */
}

.cancel-btn {
  color: #FF5252;
  border: 2px solid #FF5252;
}

.cancel-btn:hover {
  background-color: #FF5252;
  color: white;
}

.grey-hover:hover {
  background-color: #FAFAFA;
}

.b-bar {
  position: fixed;
  top: 90%;
  width: 81%;
}