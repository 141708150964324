.onProcess {
    background-color: #E3F2FD;
    color: #1976D2;
}

.pending {
    background-color: #FBF5D8;
    color: #E9911E;
}

.success {
    background-color: #E0FBD8;
    color: #3E9541;
}

.delay {
    background-color: #FFCDD2;
    color: #F44336;
}

.draft {
    background-color: #F5F5F5;
    color: #757575;
}