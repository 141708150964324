.complete-tag{
    position: absolute;
    top: -16%;
    right: 0%;
    font-size: 11px;
    background-color: #2196F3;
    padding-top: 0.5%;
    color: white;
    z-index: -1;
    font-weight: 500;
    width: 93px;
    height: 43px;
    text-align: center;
}

.bg-eee{
    background-color: #EEEEEE;
}