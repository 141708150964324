.button-hover:hover {
    background-color: #212121;
    color: white;
}

.button-hover {
    color: #767676;
}

.p-table thead td {
    color: #616161;
    font-weight: 600;
}

.draft {
    color: #9E9E9E;
    background-color: #EEEEEE;
}

.draft:hover {
    color: #2962FF;
    background-color: #EEEEEE;
    border-color: #2962FF;
}

.cancel {
    border: 1.5px solid #EEEEEE;
}

.cancel:hover {
    color: red;
    border-color: red;
}

.table-tr:hover {
    background-color: #EEEEEE;
}