.btn-hover:hover {
    background-color: #1E88E5;
    color: white;
    border: #1E88E5;
}

/* transaction-table CSS */

.t-table {
    font-size: 90%;
}

.t-table th {
    color: #757575;
}

.table-container {
    overflow-x: auto;
}

.t-table {
    min-width: 130%;
}