.tabs {
  display: flex;
  border: 1px solid #E1E1E1;
  border-radius: 12.43px;
  overflow: hidden;
}

.tab {
  flex: 1;
  padding: 10px 20px;
  text-align: center;
  cursor: pointer;
  color: #606B85;
  transition: background-color 0.3s ease;
}

.tab.active {
  background-color: #C7F9CC;
  color: #005D58;
  border-radius: 12.43px;
}

textarea {
  resize: none;
}

/* Toggle button */

.toggle-switch {
  width: 47px;
  height: 22px;
  background-color: #ccc;
  border-radius: 25px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s;
  padding: 1;
}

.toggle-switch.on {
  background-color: #3b82f6; /* Blue color when on */
}

.toggle-handle {
  width: 20px;
  height: 20px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 1px;
  left: 1px;
  transition: left 0.3s;
}

.toggle-switch.on .toggle-handle {
  left: 26px; /* Move to the right when on */
}
