.form-control::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--grey);
  opacity: 0.5;
  /* Firefox */
}

.form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--grey);
}

.form-control::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--grey);
}